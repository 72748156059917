import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 666.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,666.000000) scale(0.100000,-0.100000)">
					<path d="M5993 6423 c43 -2 110 -2 150 0 40 1 5 3 -78 3 -82 0 -115 -2 -72 -3z"/>
<path d="M5816 6405 c4 -10 10 -12 18 -7 6 5 18 12 26 15 8 3 1 6 -17 6 -25 1
-31 -2 -27 -14z"/>
<path d="M6340 6414 c14 -2 48 -9 76 -15 32 -7 57 -8 68 -2 12 7 7 9 -21 10
-21 0 -63 3 -93 6 -30 3 -44 4 -30 1z"/>
<path d="M5634 6399 c-22 -5 -17 -7 21 -7 28 0 64 4 80 8 24 6 20 7 -21 6 -27
0 -63 -4 -80 -7z"/>
<path d="M6520 6394 c8 -1 67 -10 130 -19 63 -9 122 -20 130 -24 8 -4 26 -5
40 -1 21 5 18 7 -20 13 -25 4 -90 13 -144 22 -84 13 -184 20 -136 9z"/>
<path d="M5568 6383 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5730 6380 c-35 -7 -35 -7 -5 -9 17 0 37 4 45 9 17 11 11 11 -40 0z"/>
<path d="M5503 6373 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M6477 6373 c-3 -5 16 -7 41 -5 37 2 44 1 32 -8 -13 -10 -12 -11 3 -6
10 3 24 6 30 7 7 0 3 4 -8 9 -25 11 -92 13 -98 3z"/>
<path d="M5410 6360 c-35 -7 -35 -7 -5 -9 17 0 37 4 45 9 17 11 11 11 -40 0z"/>
<path d="M5493 6353 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M6630 6350 c8 -5 24 -10 34 -10 26 0 18 6 -19 14 -22 5 -26 4 -15 -4z"/>
<path d="M5318 6343 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5568 6343 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6898 6343 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6960 6325 c14 -8 36 -14 50 -14 23 0 23 1 5 8 -11 4 -33 11 -50 15
l-30 6 25 -15z"/>
<path d="M5205 6320 c-16 -5 -75 -20 -130 -34 -55 -15 -114 -33 -132 -41 -17
-8 -35 -15 -41 -15 -5 0 -29 -9 -53 -20 -28 -12 -37 -20 -23 -20 11 0 24 4 30
9 5 4 30 14 57 21 26 7 47 16 47 20 0 4 26 13 58 19 31 7 69 17 83 23 15 5 33
8 40 7 8 -1 27 3 42 9 16 7 34 12 40 12 7 0 19 5 27 10 13 9 13 10 0 9 -8 -1
-28 -5 -45 -9z"/>
<path d="M5265 6300 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7020 6299 c-24 -8 -23 -8 5 -5 17 3 46 1 65 -4 19 -4 27 -8 18 -9
-10 -1 -18 -6 -18 -11 0 -7 6 -7 18 -1 10 5 39 5 71 0 82 -13 73 -4 -16 16
-112 24 -115 24 -143 14z"/>
<path d="M5178 6273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5228 6273 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M6968 6273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7274 6245 c15 -8 36 -15 45 -14 9 0 3 6 -14 14 -41 19 -67 19 -31 0z"/>
<path d="M7353 6216 c0 -6 10 -12 21 -12 12 -1 41 -7 65 -13 70 -19 57 -7 -18
15 -42 13 -68 16 -68 10z"/>
<path d="M4918 6193 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5958 6183 c23 -2 61 -2 85 0 23 2 4 4 -43 4 -47 0 -66 -2 -42 -4z"/>
<path d="M7318 6183 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5533 6173 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M6138 6173 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6240 6170 c30 -5 71 -8 90 -8 28 1 25 2 -15 8 -27 5 -68 8 -90 8
-35 -1 -33 -2 15 -8z"/>
<path d="M7522 6166 c7 -8 33 -22 58 -31 74 -27 88 -27 35 0 -27 13 -57 25
-65 25 -9 0 -21 4 -28 10 -8 6 -8 5 0 -4z"/>
<path d="M4740 6160 c-13 -8 -13 -10 2 -10 9 0 20 5 23 10 8 13 -5 13 -25 0z"/>
<path d="M5262 6159 c18 -5 12 -8 -25 -14 -26 -4 -46 -9 -44 -12 4 -4 218 24
242 31 6 2 -36 3 -93 2 -57 0 -93 -4 -80 -7z"/>
<path d="M6523 6163 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4685 6147 c-11 -8 -38 -21 -60 -31 -22 -9 -29 -14 -15 -11 14 4 32
7 40 8 8 1 20 6 25 10 6 4 19 14 30 22 25 18 6 20 -20 2z"/>
<path d="M6454 6145 c58 -10 100 -12 120 -6 28 8 26 9 -19 9 -27 0 -81 3 -120
6 -68 5 -67 5 19 -9z"/>
<path d="M5133 6123 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M6606 6120 c5 -5 25 -12 44 -16 l35 -6 -29 16 c-32 18 -66 22 -50 6z"/>
<path d="M5083 6103 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M7675 6100 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M4498 6062 c-37 -20 -66 -38 -63 -40 2 -2 16 3 32 11 15 8 42 21 58
28 30 14 64 40 48 38 -4 -1 -38 -18 -75 -37z"/>
<path d="M5028 6093 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6751 6090 c9 -5 36 -12 60 -15 l44 -5 -50 14 c-58 17 -77 19 -54 6z"/>
<path d="M7710 6094 c0 -3 26 -17 57 -31 45 -21 53 -28 38 -33 -11 -4 1 -7 30
-7 l49 0 -35 16 c-18 8 -56 25 -83 38 -54 25 -56 26 -56 17z"/>
<path d="M4890 6069 c-41 -11 -82 -27 -90 -36 -9 -10 -10 -13 -2 -9 7 5 57 21
110 36 110 32 96 39 -18 9z"/>
<path d="M6885 6076 l-30 -13 36 -12 c36 -12 69 -7 69 10 0 5 -4 9 -10 9 -5 0
-10 -4 -10 -9 0 -5 -10 -7 -21 -4 -21 6 -21 7 -4 20 23 16 9 16 -30 -1z"/>
<path d="M7725 6050 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4538 6043 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6968 6023 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7708 6023 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4370 5996 c-19 -13 -77 -49 -127 -81 -51 -32 -93 -59 -93 -62 0 -2
9 1 21 7 13 8 29 8 47 2 15 -5 36 -5 47 -1 l20 9 -20 -1 c-41 -2 -56 1 -50 12
4 6 13 8 21 5 7 -3 16 0 20 6 4 7 3 8 -4 4 -7 -4 -12 -2 -12 3 0 6 5 11 12 11
6 0 25 11 42 24 17 13 40 26 51 30 22 7 84 56 69 55 -5 0 -25 -11 -44 -23z"/>
<path d="M4610 6016 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z"/>
<path d="M7040 6009 c19 -5 46 -12 60 -15 20 -4 22 -3 9 5 -9 6 -36 13 -60 15
-44 6 -44 6 -9 -5z"/>
<path d="M7898 6013 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4663 5998 c-35 -17 -27 -29 10 -14 17 7 39 16 47 19 9 3 5 5 -10 6
-14 0 -35 -5 -47 -11z"/>
<path d="M7940 5985 c33 -19 50 -18 26 0 -11 8 -27 15 -35 15 -10 -1 -7 -6 9
-15z"/>
<path d="M7150 5975 c34 -20 60 -20 34 0 -10 8 -28 14 -39 14 -18 0 -17 -1 5
-14z"/>
<path d="M4577 5964 c-16 -9 -27 -17 -25 -20 5 -5 68 25 68 32 0 7 -15 3 -43
-12z"/>
<path d="M7227 5958 c-2 -9 29 -24 87 -45 50 -18 99 -37 110 -43 14 -9 17 -9
14 2 -3 7 -39 24 -81 38 -41 14 -84 33 -95 43 -22 20 -29 21 -35 5z"/>
<path d="M7940 5961 c0 -8 41 -23 47 -17 2 1 -8 8 -22 14 -14 6 -25 8 -25 3z"/>
<path d="M7990 5965 c0 -3 19 -15 43 -25 75 -36 131 -70 112 -70 -26 0 -12
-11 16 -13 22 -2 37 -9 94 -45 11 -7 35 -19 53 -27 17 -9 32 -19 32 -23 0 -9
99 -62 115 -62 5 0 -7 11 -27 25 -20 14 -40 25 -44 25 -3 0 -36 19 -73 43
-111 71 -209 127 -223 127 -4 0 -8 4 -8 9 0 5 -13 12 -30 16 -16 4 -32 11 -35
16 -7 10 -25 13 -25 4z"/>
<path d="M4338 5943 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4397 5939 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M4500 5925 c-14 -8 -20 -14 -15 -14 6 0 21 6 35 14 14 8 21 14 15 14
-5 0 -21 -6 -35 -14z"/>
<path d="M7358 5933 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7398 5923 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4286 5902 c-3 -5 1 -15 9 -22 21 -17 27 -9 10 13 -7 10 -16 14 -19
9z"/>
<path d="M4406 5884 c-23 -23 -20 -24 14 -8 17 9 30 17 30 20 0 10 -30 2 -44
-12z"/>
<path d="M8080 5890 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4338 5853 c-10 -2 -18 -9 -18 -14 0 -6 10 -5 23 2 37 20 36 23 -5
12z"/>
<path d="M7484 5846 c15 -9 32 -16 37 -16 5 0 27 -11 49 -25 45 -28 59 -32 37
-11 -23 24 -81 53 -118 60 l-34 7 29 -15z"/>
<path d="M4115 5830 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M4235 5796 c-91 -50 -134 -78 -147 -93 -15 -20 -11 -18 67 32 28 18
70 44 95 59 54 31 41 34 -15 2z"/>
<path d="M4018 5757 c-37 -28 -67 -56 -68 -60 0 -10 79 50 96 72 10 13 14 13
30 1 17 -14 18 -14 5 2 -12 16 -10 19 10 27 13 5 17 10 9 10 -8 1 -45 -23 -82
-52z"/>
<path d="M8230 5800 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M4110 5780 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M7654 5765 c36 -19 47 -19 22 0 -11 8 -27 15 -35 15 -9 0 -3 -7 13
-15z"/>
<path d="M7740 5760 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7706 5741 c5 -5 20 -12 34 -15 24 -7 24 -6 6 8 -21 17 -57 23 -40 7z"/>
<path d="M4050 5690 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7810 5687 c14 -8 27 -12 30 -10 7 6 -23 23 -40 23 -8 0 -4 -6 10
-13z"/>
<path d="M3884 5649 c-19 -19 -22 -29 -13 -34 7 -5 10 -4 6 2 -8 13 20 39 30
29 4 -4 1 -12 -7 -17 -13 -9 -13 -10 0 -6 9 2 14 14 13 26 -2 12 1 20 6 16 5
-3 12 -2 15 3 13 21 -26 6 -50 -19z"/>
<path d="M4012 5664 c-13 -6 -20 -14 -14 -18 10 -6 50 19 42 27 -3 2 -16 -2
-28 -9z"/>
<path d="M8480 5671 c0 -5 8 -11 18 -15 9 -3 32 -15 51 -26 18 -12 35 -19 37
-17 4 4 -92 67 -102 67 -2 0 -4 -4 -4 -9z"/>
<path d="M7885 5661 c-16 -6 -9 -13 44 -44 67 -40 71 -41 71 -28 0 5 -15 14
-32 21 -41 16 -69 40 -62 51 6 10 6 10 -21 0z"/>
<path d="M3960 5630 c-6 -12 -18 -19 -25 -16 -9 3 -12 0 -8 -10 3 -8 -1 -14
-10 -14 -15 0 -69 -39 -55 -40 16 0 106 61 112 77 10 25 -1 27 -14 3z"/>
<path d="M3803 5576 c-21 -19 -33 -35 -28 -35 14 0 78 59 72 66 -3 2 -23 -11
-44 -31z"/>
<path d="M8595 5590 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M8024 5565 c21 -16 32 -13 21 4 -3 6 -14 11 -23 11 -15 -1 -15 -2 2
-15z"/>
<path d="M8591 5564 c13 -16 29 -19 29 -5 0 5 -9 11 -21 14 -16 4 -18 3 -8 -9z"/>
<path d="M8649 5561 c13 -12 28 -21 34 -21 12 0 11 0 -28 24 l-30 17 24 -20z"/>
<path d="M3823 5539 c10 -6 14 -15 10 -22 -4 -7 1 -5 11 3 17 13 17 14 -1 21
-27 11 -40 10 -20 -2z"/>
<path d="M8104 5510 c26 -17 50 -29 52 -27 6 6 -49 44 -76 52 -14 4 -4 -6 24
-25z"/>
<path d="M3726 5500 c-16 -17 -26 -33 -23 -36 7 -7 59 46 55 57 -2 5 -16 -5
-32 -21z"/>
<path d="M8680 5520 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M8720 5511 c0 -11 48 -47 54 -40 2 2 -10 14 -25 27 -16 13 -29 19
-29 13z"/>
<path d="M3778 5486 c-15 -13 -24 -27 -21 -30 4 -3 -1 -6 -10 -6 -9 0 -21 -9
-27 -21 -7 -12 -18 -18 -26 -15 -7 3 -18 -3 -24 -14 -22 -41 9 -25 80 40 41
38 71 69 65 69 -5 0 -22 -10 -37 -23z m-97 -99 c-10 -9 -11 -8 -5 6 3 10 9 15
12 12 3 -3 0 -11 -7 -18z"/>
<path d="M8727 5469 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M8220 5428 c34 -24 70 -49 80 -56 14 -10 20 -10 26 -1 4 7 3 10 -3 6
-6 -4 -21 5 -33 18 -13 14 -29 25 -37 25 -7 0 -13 4 -13 9 0 8 -60 41 -75 41
-4 0 21 -19 55 -42z"/>
<path d="M3687 5449 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M8795 5448 c3 -8 19 -25 37 -38 79 -63 304 -270 294 -270 -2 0 17
-21 41 -46 25 -25 47 -43 51 -39 10 10 -290 299 -400 385 -16 12 -26 15 -23 8z"/>
<path d="M3645 5410 c-16 -16 -25 -30 -19 -29 8 0 60 59 52 59 -3 0 -17 -13
-33 -30z"/>
<path d="M8800 5401 c0 -10 19 -25 25 -20 1 2 -3 10 -11 17 -8 7 -14 8 -14 3z"/>
<path d="M3615 5360 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3631 5344 c-13 -13 -19 -24 -14 -24 12 0 48 38 42 44 -2 3 -15 -6
-28 -20z"/>
<path d="M3570 5322 c-31 -46 -36 -57 -21 -48 19 12 12 -10 -10 -30 -22 -19
-22 -19 -15 6 6 23 6 23 -5 5 -6 -11 -23 -32 -37 -47 -14 -15 -23 -31 -19 -34
3 -4 2 -15 -4 -26 -6 -11 -13 -17 -16 -14 -6 5 -78 -97 -96 -135 -4 -8 -16
-29 -28 -48 -11 -18 -18 -36 -15 -38 2 -3 12 10 22 29 36 70 119 178 246 322
36 40 45 65 12 32 -26 -26 -30 -9 -5 18 13 14 22 29 20 35 -2 6 -15 -6 -29
-27z m-67 -122 c-15 -26 -33 -40 -33 -25 0 6 42 55 47 55 2 0 -4 -13 -14 -30z"/>
<path d="M8341 5351 c3 -8 16 -21 29 -30 18 -12 22 -13 19 -2 -3 8 -16 21 -29
30 -18 12 -22 13 -19 2z"/>
<path d="M8887 5316 c-4 -10 -1 -13 9 -9 7 3 14 9 14 14 0 14 -17 10 -23 -5z"/>
<path d="M8420 5281 c0 -10 50 -45 56 -39 2 3 -10 14 -26 27 -17 12 -30 17
-30 12z"/>
<path d="M8960 5260 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8518 5214 c-6 -9 50 -79 58 -72 2 3 -7 17 -20 31 -13 14 -26 31 -28
38 -3 8 -7 9 -10 3z"/>
<path d="M9060 5165 c13 -14 26 -25 28 -25 3 0 -5 11 -18 25 -13 14 -26 25
-28 25 -3 0 5 -11 18 -25z"/>
<path d="M8654 5076 c38 -36 73 -66 77 -66 4 0 14 -12 21 -27 7 -14 20 -29 28
-31 13 -4 13 -2 -1 14 -9 10 -19 27 -22 37 -4 9 -12 17 -20 17 -19 0 -57 43
-57 63 0 10 -8 17 -19 17 -11 0 -23 4 -26 10 -3 5 -16 15 -28 21 -12 6 9 -18
47 -55z"/>
<path d="M8693 5118 c12 -13 23 -20 25 -16 5 10 -23 38 -37 38 -6 0 0 -10 12
-22z"/>
<path d="M9120 5103 c0 -12 38 -48 45 -41 3 2 -6 15 -20 28 -14 13 -25 19 -25
13z"/>
<path d="M9230 5032 c0 -6 5 -14 11 -17 6 -4 8 -13 5 -21 -3 -8 0 -11 9 -8 7
3 28 -10 47 -28 40 -41 7 3 -39 51 -18 19 -33 29 -33 23z"/>
<path d="M8811 4947 c2 -1 12 -9 22 -16 14 -12 17 -11 17 3 0 10 -8 16 -22 16
-12 0 -19 -1 -17 -3z"/>
<path d="M8847 4878 c11 -13 26 -32 32 -43 11 -18 11 -18 5 5 -7 29 -32 60
-47 60 -6 0 -1 -10 10 -22z"/>
<path d="M9360 4881 c0 -5 5 -13 11 -17 6 -3 9 -14 6 -23 -3 -11 3 -16 20 -17
l23 -2 -21 29 c-23 32 -39 45 -39 30z"/>
<path d="M8926 4785 c15 -20 29 -33 31 -31 7 7 -36 66 -48 66 -6 0 2 -16 17
-35z"/>
<path d="M9440 4784 c0 -6 9 -20 20 -32 11 -12 20 -25 20 -30 0 -5 11 -18 25
-30 14 -12 25 -28 25 -36 0 -7 7 -19 15 -26 8 -7 15 -21 15 -31 0 -13 7 -19
23 -19 12 0 17 3 11 8 -6 4 -16 17 -23 29 -10 20 -101 143 -122 168 -6 5 -9 5
-9 -1z"/>
<path d="M8980 4724 c0 -6 8 -19 19 -28 17 -15 19 -15 25 -1 9 24 8 28 -8 22
-7 -3 -19 0 -25 6 -7 7 -11 7 -11 1z"/>
<path d="M9445 4710 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5680 4691 c0 -8 -7 -10 -20 -6 -11 3 -20 3 -20 0 0 -13 21 -45 29
-45 10 0 24 50 16 58 -3 3 -5 0 -5 -7z"/>
<path d="M9020 4666 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M9095 4630 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M9060 4624 c0 -4 22 -36 48 -73 27 -36 59 -83 71 -103 24 -40 40 -53
21 -17 -7 11 -9 27 -6 35 3 9 1 12 -5 9 -6 -4 -7 3 -3 22 6 24 5 26 -8 16 -12
-11 -22 -2 -64 52 -48 64 -54 71 -54 59z"/>
<path d="M9527 4603 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M8821 4544 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9600 4554 c0 -5 11 -25 23 -44 13 -19 29 -48 35 -65 9 -25 11 -26
11 -8 1 12 -8 39 -20 60 -23 42 -49 72 -49 57z"/>
<path d="M8841 4495 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z"/>
<path d="M9680 4435 c0 -3 9 -23 20 -45 11 -22 20 -49 20 -61 0 -12 8 -23 19
-26 14 -4 51 -59 51 -78 0 -15 -20 -2 -21 13 0 9 -3 12 -5 5 -3 -7 0 -22 7
-34 11 -21 12 -21 21 -5 13 22 -17 92 -44 107 -11 6 -17 16 -15 22 2 7 -7 33
-21 60 -22 43 -32 56 -32 42z"/>
<path d="M9248 4413 c6 -2 12 -11 12 -19 0 -9 5 -12 10 -9 13 8 -6 35 -24 34
-7 0 -6 -2 2 -6z"/>
<path d="M9220 4397 c0 -9 19 -44 41 -74 l17 -23 -2 24 c0 14 -6 22 -13 19 -7
-3 -13 1 -13 9 0 15 -29 59 -30 45z"/>
<path d="M4840 4286 c0 -19 4 -23 20 -19 11 3 20 9 20 14 0 5 15 9 33 10 27 0
29 2 12 9 -11 5 -35 9 -52 9 -28 1 -33 -2 -33 -23z"/>
<path d="M4960 4290 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1780 4271 c0 -13 9 -56 21 -94 23 -79 28 -77 53 18 12 46 12 61 3
67 -7 4 -15 0 -19 -10 -4 -10 -7 -11 -7 -4 -1 9 -8 11 -22 7 -12 -4 -19 -3
-16 2 3 5 2 15 -4 23 -7 12 -9 10 -9 -9z"/>
<path d="M9300 4254 c0 -7 9 -22 20 -35 10 -13 19 -19 20 -13 0 7 -9 22 -20
35 -10 13 -19 19 -20 13z"/>
<path d="M620 4230 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1061 4184 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9341 4189 c-1 -3 19 -46 44 -95 25 -48 45 -94 45 -101 0 -7 8 -13
18 -13 10 0 23 -5 30 -12 9 -9 12 -8 12 4 0 9 -10 18 -21 20 -12 2 -25 10 -29
18 -4 8 -20 41 -35 73 -15 31 -32 60 -37 63 -5 3 -7 13 -4 21 3 8 2 12 -3 8
-5 -3 -12 1 -14 7 -3 7 -6 10 -6 7z"/>
<path d="M9421 4159 c-7 -12 -10 -23 -8 -26 3 -3 11 5 17 17 15 28 8 35 -9 9z"/>
<path d="M9847 4115 c3 -16 12 -43 20 -58 15 -30 9 -53 -7 -27 -7 11 -10 8
-15 -13 -4 -14 -5 -32 -2 -39 3 -7 6 -2 6 12 1 19 6 26 21 26 11 0 20 -6 20
-12 0 -6 7 -17 15 -24 8 -7 15 -10 15 -8 0 4 -29 69 -63 138 -16 34 -16 34
-10 5z"/>
<path d="M822 4110 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M8106 4085 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z"/>
<path d="M1616 4067 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M2021 4064 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5066 4055 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z"/>
<path d="M5872 4037 c-7 -19 -12 -40 -12 -48 1 -8 8 6 16 29 9 24 14 46 12 48
-2 2 -10 -11 -16 -29z"/>
<path d="M8767 4049 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z"/>
<path d="M2536 4045 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M4220 3805 c-3 -137 -7 -499 -8 -803 -1 -304 -7 -599 -12 -655 -11
-106 -38 -215 -71 -279 -10 -20 -19 -40 -19 -43 0 -3 54 -5 120 -5 110 0 330
-16 540 -40 100 -11 380 -13 396 -2 19 13 109 239 128 324 3 14 9 34 13 45 4
10 -17 -4 -47 -30 -93 -84 -181 -117 -312 -117 -190 0 -273 83 -298 298 -10
90 -14 1152 -4 1152 3 0 30 -11 60 -25 30 -14 58 -25 61 -25 29 0 294 -153
348 -201 11 -10 41 -37 67 -60 107 -93 203 -258 231 -397 9 -41 18 -76 20 -79
3 -2 10 20 15 49 6 29 15 74 21 99 14 66 92 359 102 386 7 19 1 27 -39 56 -96
71 -293 157 -338 149 -13 -3 -27 25 -19 38 3 4 42 13 87 19 108 16 238 61 238
83 0 5 6 8 13 8 17 0 77 62 77 80 0 10 -4 9 -18 -3 -10 -10 -26 -17 -36 -17
-12 0 -16 -5 -12 -18 6 -17 -4 -17 -152 -15 -86 2 -173 6 -192 9 -33 6 -34 6
-15 21 20 15 20 15 -2 10 -12 -3 -24 0 -26 7 -3 7 -10 5 -23 -6 -38 -34 -164
-40 -279 -13 -52 12 -115 37 -115 46 0 5 -8 9 -17 9 -28 0 37 -49 65 -50 12 0
22 -6 22 -12 0 -15 10 -14 -312 -16 l-238 -2 -2 -42 c-1 -24 -5 38 -8 137 l-5
180 -5 -250z m906 -4 c-4 -5 -1 -11 5 -13 6 -2 -9 -4 -32 -5 l-44 -1 35 13
c19 7 36 13 38 14 2 0 1 -3 -2 -8z m-281 -11 l20 -9 -20 0 c-11 0 -27 4 -35 9
-13 9 -13 10 0 9 8 0 24 -4 35 -9z m633 -42 c-3 -7 -13 -15 -24 -17 -16 -3
-17 -1 -5 13 16 19 34 21 29 4z m-115 -55 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5
14 0 19 -2 13 -5z m-68 -13 c-11 -5 -38 -8 -60 -8 l-40 1 45 7 c64 10 78 10
55 0z m-108 -6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-467
-24 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15 10 0 2 7 4 15 4 8 0 15 -4 15 -10z"/>
<path d="M4841 4034 c-1 -24 36 -45 77 -43 21 1 17 4 -17 13 -27 7 -47 19 -52
31 -7 19 -8 19 -8 -1z"/>
<path d="M1630 4031 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M6101 4004 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6400 4000 c6 -11 13 -20 16 -20 2 0 0 9 -6 20 -6 11 -13 20 -16 20
-2 0 0 -9 6 -20z"/>
<path d="M7071 3920 c-2 -58 -7 -451 -11 -875 -8 -818 -9 -823 -59 -969 -12
-33 -21 -63 -21 -68 0 -4 42 -8 93 -8 l93 0 31 78 c63 157 91 260 206 737 26
105 56 231 68 280 20 83 22 112 20 365 -2 321 -7 397 -27 397 -10 0 -14 -12
-14 -39 l0 -38 -165 0 -165 0 -2 43 -1 42 -7 -45 -6 -45 -5 45 c-4 33 -7 20
-9 -50 -4 -80 -5 -68 -10 80 -5 155 -6 163 -9 70z m396 -197 c-2 -16 -4 -5 -4
22 0 28 2 40 4 28 2 -13 2 -35 0 -50z"/>
<path d="M8086 4005 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z"/>
<path d="M8380 4010 c0 -8 5 -22 10 -30 9 -13 10 -13 10 0 0 8 -5 22 -10 30
-9 13 -10 13 -10 0z"/>
<path d="M1991 3984 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4970 3990 c-20 -6 -21 -8 -5 -8 11 0 29 3 40 8 25 11 -1 11 -35 0z"/>
<path d="M1257 3960 c9 -53 24 -83 17 -35 -4 22 -10 47 -15 55 -5 9 -6 1 -2
-20z"/>
<path d="M6091 3964 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6810 3975 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M2507 3963 c-4 -3 -7 -17 -7 -29 0 -13 -4 -25 -9 -28 -14 -9 -20 -45
-9 -53 7 -3 8 -1 4 5 -4 7 -3 12 1 12 5 0 14 21 21 46 12 46 12 61 -1 47z"/>
<path d="M5245 3940 c-9 -17 -35 -49 -58 -71 -23 -21 -37 -39 -32 -39 25 0
125 123 112 137 -3 3 -13 -9 -22 -27z"/>
<path d="M791 3944 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5651 3944 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6421 3945 c0 -21 27 -88 28 -70 0 6 -6 28 -14 50 -8 22 -14 31 -14
20z"/>
<path d="M6440 3954 c0 -6 6 -17 14 -23 11 -9 15 -8 20 5 9 24 7 31 -4 14 -9
-13 -11 -13 -20 0 -5 8 -10 10 -10 4z"/>
<path d="M9925 3930 c5 -35 45 -140 56 -150 3 -3 10 -21 17 -40 l11 -35 -6 35
c-3 19 -9 40 -13 45 -4 6 -14 30 -23 55 -9 25 -23 63 -32 85 l-16 40 6 -35z"/>
<path d="M5610 3940 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M7752 3733 c-88 -266 -133 -417 -178 -588 -47 -183 -47 -181 -120
-480 -73 -301 -122 -474 -165 -583 -16 -40 -29 -75 -29 -78 0 -4 91 -5 203 -2
191 3 205 5 252 28 45 22 54 33 86 100 20 41 39 87 43 101 5 25 3 27 -35 32
-120 16 -138 159 -62 475 l18 72 469 -2 469 -3 23 -100 c12 -55 28 -139 35
-187 15 -109 7 -342 -15 -418 -9 -30 -18 -65 -22 -77 l-6 -23 229 0 c236 0
309 7 376 37 32 14 43 28 77 97 21 45 41 95 44 112 l5 32 -55 4 c-104 9 -139
55 -183 244 -18 77 -37 153 -42 169 -5 17 -41 152 -80 300 -39 149 -77 290
-84 315 -7 25 -20 70 -28 100 -35 127 -97 326 -107 342 -5 10 -10 22 -10 27 0
5 -5 22 -11 38 l-12 28 -8 -29 c-10 -34 -3 -48 12 -26 9 12 10 12 5 -3 -3 -10
-6 -21 -6 -24 0 -3 -7 0 -15 7 -12 10 -15 10 -15 -1 0 -11 -2 -11 -8 -2 -6 9
-55 13 -167 15 l-159 3 -12 45 c-7 27 -12 36 -13 22 -1 -15 -5 -21 -13 -16 -7
5 -10 2 -6 -7 2 -8 15 -52 29 -99 13 -47 26 -87 30 -90 3 -3 6 -12 6 -20 1 -8
17 -76 37 -150 53 -196 96 -369 96 -386 0 -12 -58 -14 -395 -14 -370 0 -395 1
-395 18 0 19 43 183 95 362 18 63 36 131 40 150 4 19 13 48 21 65 24 58 66
221 61 241 -3 14 -5 12 -6 -8 -2 -44 -20 -81 -36 -74 -18 6 -19 -7 -4 -36 8
-14 8 -23 0 -31 -8 -8 -11 -6 -11 6 0 15 -11 17 -80 17 l-80 0 0 28 -1 27 -14
-30 -15 -30 6 35 c4 19 10 37 15 38 5 2 9 11 9 20 0 14 -2 15 -10 2 -15 -24
-21 -6 -9 28 6 17 9 37 7 43 -2 6 -36 -87 -76 -208z m37 53 c-9 -8 -10 -7 -5
7 3 10 7 24 7 30 1 7 3 4 6 -7 3 -10 -1 -23 -8 -30z m668 7 c-3 -10 -5 -4 -5
12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m403 -64 c0 -5 -4 -9 -10 -9 -5 0 -10 7
-10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m-923 -91 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M2206 3914 c-4 -14 -5 -28 -3 -31 3 -2 8 8 11 23 4 14 5 28 3 31 -3
2 -8 -8 -11 -23z"/>
<path d="M4041 3930 c1 -24 15 -55 21 -44 2 5 -1 20 -8 34 -8 14 -13 18 -13
10z"/>
<path d="M4091 3820 c-3 -91 -5 -112 -10 -79 -4 26 -11 53 -17 60 -6 8 -17 40
-24 72 -7 31 -15 55 -18 53 -2 -3 3 -34 12 -70 9 -35 15 -68 14 -73 -1 -4 4
-69 11 -144 19 -190 10 -541 -18 -709 -84 -496 -279 -729 -594 -707 -67 4 -93
12 -154 43 -182 92 -290 289 -359 654 -22 116 -31 624 -14 770 6 58 14 128 15
155 4 44 3 42 -7 -15 -10 -55 -12 -61 -18 -35 -3 20 -8 26 -13 17 -5 -7 -6
-20 -3 -30 3 -9 1 -30 -4 -47 -10 -29 -10 -29 -6 8 l5 37 -210 0 -211 0 6 33
c6 29 5 30 -5 7 -5 -14 -19 -81 -29 -150 -22 -142 -27 -463 -10 -597 49 -365
162 -622 369 -836 68 -71 221 -174 313 -210 284 -112 673 -78 908 81 63 43 72
65 95 232 12 84 14 229 13 715 -2 336 -5 673 -8 750 l-6 140 -4 -165 -4 -165
-6 165 -6 165 -3 -125z m-24 -77 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2
-21 0 -30z m-1597 -30 c-10 -16 -11 -15 -6 7 3 14 6 32 7 40 1 8 4 5 6 -7 2
-12 -1 -30 -7 -40z m427 -45 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0
-25z"/>
<path d="M9460 3933 c0 -5 8 -17 17 -28 14 -18 15 -18 9 -2 -3 10 -6 23 -6 28
0 5 -4 9 -10 9 -5 0 -10 -3 -10 -7z"/>
<path d="M2940 3914 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M7452 3910 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M8805 3913 c4 -10 9 -27 12 -38 4 -16 3 -17 -6 -5 -13 18 -13 3 -1
-25 7 -16 9 -17 9 -3 1 9 6 18 11 20 7 2 5 15 -5 36 -17 33 -32 45 -20 15z"/>
<path d="M1962 3893 c-10 -25 -47 -151 -72 -243 -13 -48 -61 -222 -66 -239 -8
-25 -21 2 -34 69 -19 102 -99 404 -105 398 -2 -2 1 -24 7 -48 6 -25 10 -54 10
-64 -1 -11 4 -28 9 -39 8 -13 8 -22 0 -30 -8 -8 -11 -3 -11 17 0 15 -4 25 -8
22 -4 -2 -7 12 -5 32 2 20 0 31 -3 25 -4 -10 -48 -13 -170 -13 -115 0 -164 -3
-164 -11 0 -25 -30 14 -36 47 -3 19 -11 37 -17 41 -6 4 -8 0 -5 -8 3 -8 20
-59 37 -114 18 -55 36 -107 41 -116 11 -20 161 -423 190 -509 52 -153 85 -329
78 -414 -2 -25 -1 -46 3 -46 10 0 128 163 159 220 100 184 186 380 280 640 23
63 46 125 51 138 5 13 9 33 9 46 0 13 6 29 14 35 8 6 16 26 19 44 2 18 9 47
15 65 6 18 7 30 1 27 -5 -4 -14 -25 -20 -48 l-10 -42 -82 3 c-45 2 -85 8 -88
14 -4 6 -9 5 -13 -3 -7 -15 1 33 11 65 3 12 1 16 -6 12 -6 -4 -11 -17 -11 -30
0 -12 -7 -31 -14 -42 -13 -17 -13 -12 -2 36 7 30 17 63 21 73 4 9 5 17 2 17
-2 0 -9 -12 -15 -27z m130 -225 c-8 -8 -9 -4 -5 13 4 13 8 18 11 10 2 -7 -1
-18 -6 -23z m-175 0 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-183 -14 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m-27 -26
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M5621 3879 c-12 -23 -19 -44 -18 -46 2 -1 13 16 25 39 13 22 20 43
18 45 -3 3 -14 -14 -25 -38z"/>
<path d="M5786 3776 c-27 -80 -45 -150 -41 -157 5 -8 4 -10 -3 -5 -7 4 -12 5
-12 2 0 -3 -25 -88 -56 -188 -52 -174 -115 -416 -219 -843 -53 -217 -93 -355
-136 -470 -17 -44 -33 -88 -36 -97 -5 -17 7 -18 158 -18 271 0 326 17 373 114
21 42 56 131 56 141 0 1 -19 5 -42 8 -113 15 -135 148 -71 430 l26 117 469 0
c257 0 468 -2 468 -5 0 -2 11 -48 24 -102 14 -54 30 -140 37 -190 16 -128 7
-336 -20 -430 -12 -40 -21 -75 -21 -78 0 -3 32 -5 71 -3 l72 3 28 70 c60 152
61 168 66 790 5 615 7 587 -49 770 -15 45 -28 73 -31 65 -3 -8 -4 -3 -1 11 3
14 -2 45 -11 70 -9 24 -24 65 -33 92 -9 26 -19 47 -24 47 -10 0 -22 -49 -14
-57 3 -4 6 1 6 10 0 32 19 17 26 -20 4 -21 11 -44 17 -51 5 -7 7 -16 3 -19 -4
-4 -11 -1 -17 7 -8 13 -10 13 -18 0 -6 -8 -14 -11 -18 -6 -4 5 -2 11 5 13 6 3
11 17 11 31 -1 17 -3 21 -6 10 -2 -10 -9 -18 -14 -18 -5 0 -9 -9 -9 -20 0 -19
-7 -20 -133 -20 -90 0 -137 4 -145 12 -9 9 -12 9 -12 0 0 -29 -30 -9 -41 28
-7 22 -16 40 -22 40 -5 0 -6 -8 -2 -17 4 -10 18 -54 30 -98 12 -44 35 -123 50
-175 55 -195 64 -227 88 -330 14 -58 28 -120 32 -137 l7 -33 -395 0 c-216 0
-396 3 -399 6 -4 3 5 45 18 92 14 48 38 137 54 197 43 158 60 218 105 363 24
81 34 143 15 95 -13 -33 -24 -28 -16 8 5 26 4 28 -9 16 -8 -8 -17 -27 -21 -42
-7 -26 -7 -27 -86 -24 -61 3 -81 0 -88 -11 -9 -13 -10 -13 -10 0 0 13 -1 13
-10 0 -5 -8 -12 -11 -17 -7 -4 5 -3 11 3 15 10 7 48 132 41 138 -2 2 -25 -61
-51 -140z m234 -33 c-11 -17 -11 -17 -6 0 3 10 6 24 7 30 0 9 2 9 5 0 3 -7 0
-20 -6 -30z m-180 -24 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6
-3 10 -11 10 -16z m680 -34 c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15
4 15 6 0 10 -7 10 -15z m29 -25 c0 -10 -3 -8 -9 5 -12 27 -12 43 0 25 5 -8 9
-22 9 -30z m360 -30 c0 -10 -3 -8 -9 5 -12 27 -12 43 0 25 5 -8 9 -22 9 -30z"/>
<path d="M8417 3888 c8 -44 13 -48 13 -10 0 17 -4 34 -10 37 -6 4 -7 -6 -3
-27z"/>
<path d="M779 3894 c-1 -26 -7 -62 -29 -174 -10 -52 -16 -98 -14 -102 3 -4 0
-15 -5 -25 -5 -10 -14 -56 -21 -103 -6 -47 -15 -112 -20 -145 -32 -232 -52
-375 -65 -470 -68 -502 -111 -733 -161 -859 -7 -17 1 -18 197 -13 119 2 221 9
244 17 60 18 125 91 153 170 27 80 27 80 -2 80 -37 0 -89 21 -118 48 -52 48
-63 99 -62 277 1 88 7 210 13 270 20 192 37 341 56 500 1 11 5 31 8 45 3 14
10 70 17 125 6 55 17 141 25 190 24 161 27 191 13 177 -7 -7 -13 -27 -13 -45
-4 -94 -6 -112 -14 -92 -5 11 -7 38 -5 60 l4 40 -11 -42 -10 -43 -80 0 c-68 0
-79 2 -80 18 -1 9 -7 -1 -15 -23 -14 -38 -14 -39 -9 -6 3 18 5 40 5 48 0 8 4
12 8 9 5 -3 7 14 5 36 -3 44 -12 65 -14 32z m195 -164 c4 -25 3 -31 -4 -20 -5
8 -10 26 -10 39 0 32 6 24 14 -19z m-217 -102 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z"/>
<path d="M972 3890 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2523 3885 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z"/>
<path d="M5250 3894 c0 -3 7 -12 15 -20 16 -17 18 -14 9 10 -6 15 -24 23 -24
10z"/>
<path d="M6460 3891 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M4640 3881 c0 -9 30 -24 36 -18 2 1 -6 8 -16 15 -11 7 -20 8 -20 3z"/>
<path d="M4072 3855 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M8016 3863 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M9490 3868 c0 -3 7 -21 15 -39 16 -34 16 -34 10 -4 -6 26 -25 60 -25
43z"/>
<path d="M1346 3831 c4 -23 3 -31 -5 -27 -6 4 -11 0 -11 -8 0 -9 5 -16 10 -16
17 0 23 28 11 57 -10 27 -11 27 -5 -6z"/>
<path d="M1995 3839 c-10 -30 -2 -32 12 -3 6 13 7 24 3 24 -5 0 -12 -9 -15
-21z"/>
<path d="M5530 3845 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z"/>
<path d="M5830 3826 c0 -8 4 -17 9 -20 5 -4 7 3 4 14 -6 23 -13 26 -13 6z"/>
<path d="M1385 3800 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M5185 3800 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6232 3703 c-25 -99 -28 -133 -7 -88 7 17 14 25 14 19 1 -14 34 -14
43 0 5 8 8 7 8 -1 0 -7 4 -13 9 -13 5 0 -1 38 -13 85 -11 47 -24 85 -27 85 -3
0 -15 -39 -27 -87z"/>
<path d="M8211 3700 c-24 -93 -23 -114 3 -87 17 16 55 12 69 -7 5 -6 5 5 2 24
-9 46 -37 143 -45 153 -3 4 -17 -33 -29 -83z"/>
<path d="M9529 3758 c13 -35 59 -180 64 -203 3 -11 4 8 3 42 0 35 -3 58 -4 53
-6 -18 -22 1 -22 26 0 13 -4 24 -10 24 -5 0 -10 11 -10 24 0 31 -9 56 -21 56
-5 0 -5 -10 0 -22z"/>
<path d="M10011 3684 c-1 -11 6 -43 14 -70 8 -27 15 -55 15 -61 0 -19 -18 -15
-23 5 -3 9 -5 1 -6 -18 0 -26 2 -31 9 -20 5 8 10 11 10 5 0 -5 4 -4 9 3 5 9
10 1 15 -27 3 -22 11 -43 17 -47 8 -5 10 0 5 17 -4 13 -20 71 -36 129 -15 58
-29 96 -29 84z"/>
<path d="M1166 3553 c-3 -21 -8 -76 -11 -123 -5 -83 -5 -84 10 -50 10 24 14
27 10 10 -4 -24 -4 -24 19 5 l24 29 22 -34 c12 -19 19 -28 17 -20 -3 8 -20 58
-37 110 -17 52 -35 99 -39 103 -5 5 -11 -9 -15 -30z"/>
<path d="M9672 3515 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M9610 3515 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M10012 3480 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M9624 3438 c3 -13 12 -52 20 -88 8 -36 18 -92 21 -125 3 -33 10 -89
15 -125 5 -36 10 -68 10 -72 0 -5 5 -8 10 -8 12 0 13 37 1 42 -10 4 -31 210
-25 253 3 29 3 29 -6 5 -8 -20 -9 -13 -6 30 2 30 1 49 -1 43 -10 -27 -23 -11
-23 27 0 22 -5 40 -10 40 -6 0 -8 -10 -6 -22z"/>
<path d="M1211 3394 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M10082 3390 c0 -14 4 -38 8 -55 7 -27 8 -27 8 -5 0 14 -4 39 -8 55
-7 27 -8 27 -8 5z"/>
<path d="M4830 3305 c0 -38 3 -44 19 -42 10 2 26 9 35 16 8 7 20 10 26 6 5 -3
10 -3 10 2 0 8 -73 63 -84 63 -3 0 -6 -20 -6 -45z"/>
<path d="M6118 3308 l-17 -48 44 0 c24 0 47 5 50 10 3 6 -3 10 -13 10 -24 0
-39 17 -44 50 -3 21 -6 18 -20 -22z"/>
<path d="M8103 3324 c-6 -16 -14 -37 -18 -46 -6 -15 -1 -18 33 -18 23 0 44 5
47 10 3 6 -1 10 -10 10 -9 0 -14 4 -10 9 3 5 0 13 -6 16 -6 4 -14 16 -18 28
-7 19 -8 19 -18 -9z"/>
<path d="M6367 3310 c-4 -18 -13 -30 -22 -30 -9 0 -13 -4 -10 -10 6 -10 75
-14 75 -5 0 10 -29 75 -33 75 -3 0 -7 -13 -10 -30z"/>
<path d="M2216 3315 c-23 -53 -64 -163 -69 -190 -6 -25 -5 -27 5 -14 9 14 16
15 35 6 38 -17 44 0 41 114 -2 71 -6 98 -12 84z"/>
<path d="M8360 3316 c0 -12 -5 -16 -14 -13 -10 4 -13 1 -9 -9 3 -7 0 -14 -6
-14 -6 0 -11 -4 -11 -10 0 -5 16 -10 36 -10 32 0 35 2 28 23 -3 12 -10 29 -15
37 -7 12 -8 12 -9 -4z"/>
<path d="M10082 3250 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M10105 3240 c17 -156 26 -370 21 -460 -13 -220 -14 -256 -5 -220 25
111 17 611 -12 697 -7 23 -8 20 -4 -17z"/>
<path d="M9721 2990 c0 -14 4 -32 9 -40 8 -12 10 -12 9 5 0 11 -4 29 -9 40 -8
18 -9 18 -9 -5z"/>
<path d="M9694 2915 c-1 -36 2 -65 7 -65 5 0 9 29 9 65 0 85 -15 85 -16 0z"/>
<path d="M9751 2914 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9723 2860 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M10103 2860 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M9331 2835 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z"/>
<path d="M9704 2730 c0 -52 1 -74 3 -47 2 26 2 68 0 95 -2 26 -3 4 -3 -48z"/>
<path d="M9743 2780 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M5837 2668 c-26 -103 -40 -222 -33 -262 7 -35 8 -37 20 -20 21 30 63
173 71 239 3 33 8 70 11 83 4 19 0 22 -25 22 -27 0 -30 -4 -44 -62z"/>
<path d="M9361 2704 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9720 2686 c0 -14 5 -26 10 -26 6 0 10 9 10 19 0 11 -4 23 -10 26 -6
4 -10 -5 -10 -19z"/>
<path d="M10091 2644 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9384 2613 c3 -16 10 -52 16 -81 5 -29 12 -57 15 -62 8 -13 105 -13
105 0 0 6 -7 10 -15 10 -8 0 -20 10 -26 23 -7 12 -23 32 -36 44 -12 12 -23 29
-23 38 0 8 -4 15 -10 15 -5 0 -10 9 -10 20 0 11 -5 20 -11 20 -7 0 -9 -10 -5
-27z"/>
<path d="M9692 2594 c-8 -22 -12 -51 -9 -65 3 -17 5 -14 6 13 0 20 6 38 12 40
6 2 9 15 7 28 -3 22 -5 20 -16 -16z"/>
<path d="M7917 2613 c-4 -3 -7 -20 -6 -37 0 -29 1 -29 9 -8 5 12 14 22 20 22
5 0 10 7 10 15 0 15 -21 21 -33 8z"/>
<path d="M6561 2594 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M10080 2571 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5
0 -10 -4 -10 -9z"/>
<path d="M7902 2520 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1071 2483 c-1 -25 6 -28 29 -13 13 8 13 10 -2 10 -9 0 -19 6 -21 13
-3 6 -6 2 -6 -10z"/>
<path d="M9673 2460 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M10101 2484 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7897 2483 c-12 -12 -7 -43 7 -43 9 0 12 8 8 25 -5 27 -6 28 -15 18z"/>
<path d="M4861 2438 c-1 -25 27 -48 57 -47 26 0 25 1 -7 13 -19 7 -38 21 -42
32 -6 15 -8 16 -8 2z"/>
<path d="M10086 2411 c-4 -24 -5 -46 -2 -48 7 -7 19 63 14 79 -3 7 -8 -7 -12
-31z"/>
<path d="M9657 2363 c-11 -10 -8 -20 7 -26 10 -4 12 0 9 14 -6 21 -7 22 -16
12z"/>
<path d="M10077 2333 c-4 -3 -7 -18 -7 -33 0 -14 -4 -30 -9 -35 -11 -13 -34
-106 -27 -112 3 -3 10 15 16 38 6 24 15 53 20 64 7 17 20 85 15 85 -1 0 -5 -3
-8 -7z"/>
<path d="M2597 2248 c-6 -72 -30 -156 -61 -216 l-17 -32 226 0 c124 0 225 2
225 4 0 2 -19 14 -42 26 -81 42 -181 130 -261 228 -28 34 -54 62 -57 62 -4 0
-10 -33 -13 -72z"/>
<path d="M9636 2269 c-4 -28 -5 -54 -1 -57 3 -3 5 7 5 22 0 15 4 25 8 22 4 -2
9 11 10 30 4 54 -15 40 -22 -17z"/>
<path d="M9612 2148 c-16 -16 -15 -28 3 -28 8 0 15 9 15 20 0 23 -2 24 -18 8z"/>
<path d="M10021 2128 c0 -9 -4 -25 -7 -35 -5 -17 -5 -17 6 0 6 10 9 25 7 35
-4 15 -5 15 -6 0z"/>
<path d="M9591 2098 c0 -9 -4 -25 -7 -35 -5 -17 -5 -17 6 0 6 10 9 25 7 35 -4
15 -5 15 -6 0z"/>
<path d="M10001 2054 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9555 2004 c-8 -20 -15 -43 -15 -50 0 -8 -4 -14 -10 -14 -5 0 -10
-10 -10 -22 0 -17 6 -12 24 22 23 45 41 100 31 100 -2 0 -12 -16 -20 -36z"/>
<path d="M9987 2023 c-2 -4 -10 -26 -18 -48 l-13 -40 -7 30 -7 30 0 -35 c-1
-68 -44 -175 -71 -175 -8 0 -16 -7 -18 -15 -4 -13 -3 -13 6 0 15 22 24 5 11
-20 -10 -18 -14 -20 -22 -9 -6 9 -7 3 -3 -20 5 -23 4 -31 -4 -27 -7 5 -9 1 -5
-8 3 -9 1 -16 -5 -16 -6 0 -11 -6 -11 -13 0 -7 -11 -29 -25 -49 -26 -38 -48
-88 -40 -88 3 0 10 10 15 23 6 12 16 31 22 41 38 59 78 126 78 131 0 3 13 33
29 68 41 85 102 240 97 244 -2 3 -6 1 -9 -4z"/>
<path d="M9926 2005 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z"/>
<path d="M9591 1984 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9511 1873 c-1 -23 -31 -86 -43 -91 -5 -2 -8 -16 -8 -30 1 -22 7 -14
31 36 17 35 28 73 26 85 l-4 22 -2 -22z"/>
<path d="M9862 1840 c7 -10 14 -18 15 -16 6 6 -9 36 -18 36 -5 0 -4 -9 3 -20z"/>
<path d="M652 1832 l-263 -2 5 -103 c3 -56 5 -319 5 -584 l1 -483 3935 0 3935
0 90 55 c391 242 694 563 915 970 21 39 49 89 62 113 l25 43 -4224 -3 c-2323
-2 -4341 -4 -4486 -6z m1346 -116 c29 -16 51 -34 49 -40 -2 -6 5 -15 16 -21
10 -5 32 -35 48 -65 25 -47 29 -67 29 -131 0 -73 -11 -154 -25 -191 -6 -16 0
-18 51 -18 33 0 81 7 108 15 28 9 95 16 155 17 l106 1 -90 6 c-49 4 -100 12
-112 19 -12 7 -49 12 -83 12 -38 0 -59 4 -55 10 3 6 30 10 58 10 29 0 77 7
107 15 30 8 89 15 130 16 l75 2 -61 7 c-33 4 -66 10 -73 14 -7 5 -47 14 -89
21 -58 10 -67 13 -37 14 22 0 84 11 138 23 98 22 99 22 65 34 -18 7 -59 13
-90 13 -32 1 -58 6 -58 11 0 6 26 10 58 10 31 1 86 7 122 15 36 8 110 15 165
16 l100 2 -100 7 c-55 4 -113 13 -128 19 -16 6 -53 11 -83 11 -30 0 -54 5 -54
10 0 6 27 10 61 10 34 0 87 6 118 14 37 9 131 16 275 18 l218 4 -5 -41 c-3
-22 -13 -80 -22 -130 -9 -49 -20 -110 -24 -135 -15 -91 -103 -575 -107 -588
-3 -11 -38 -13 -177 -9 -169 5 -394 34 -284 36 26 1 76 8 110 16 34 8 118 19
187 24 l125 8 -128 2 c-88 1 -139 6 -164 16 -19 8 -60 15 -89 15 -30 0 -54 5
-54 10 0 6 26 10 58 10 31 1 84 7 117 15 33 8 98 15 145 16 l85 2 -105 7 c-58
4 -115 12 -127 18 -12 7 -49 12 -82 12 -33 0 -63 4 -66 10 -4 6 18 10 60 10
37 0 76 5 88 12 12 6 69 15 127 18 82 5 51 7 -137 8 -139 1 -243 -2 -245 -7
-6 -19 -210 -252 -228 -261 -24 -13 -221 -12 -280 1 -25 5 -80 13 -123 18 -42
4 -80 11 -84 15 -7 6 -3 7 67 11 25 2 74 8 110 14 36 6 101 14 145 18 l80 7
-75 7 c-41 3 -91 11 -110 17 -41 14 -120 16 -120 3 0 -10 -127 -73 -181 -90
-90 -29 -578 -43 -690 -20 -30 6 -87 14 -127 17 -40 4 -73 11 -75 15 -1 4 28
9 64 11 36 2 82 9 102 15 19 7 127 16 239 20 l203 8 -195 2 c-148 1 -201 5
-220 16 -14 8 -54 15 -90 16 l-65 2 86 12 c47 7 109 18 138 24 28 6 111 12
184 13 l132 2 -155 7 c-85 3 -175 11 -200 17 -25 6 -65 11 -90 12 -67 1 -29
20 40 20 30 0 77 7 104 15 30 9 97 16 170 17 l121 2 -120 6 c-66 3 -133 12
-148 18 -16 7 -55 12 -87 12 -32 0 -62 5 -65 10 -4 6 9 10 32 10 21 0 69 7
108 15 38 8 88 15 109 15 79 0 6 23 -128 40 -87 11 -96 20 -20 20 32 0 81 7
108 15 27 8 88 16 135 16 l86 2 -76 7 c-41 4 -85 13 -97 19 -12 6 -51 14 -87
18 -79 7 -86 23 -11 23 29 0 73 7 96 15 23 8 71 15 107 15 40 0 62 4 58 10 -3
6 -23 10 -44 10 -21 0 -58 7 -81 15 -23 8 -67 15 -97 15 -34 0 -52 4 -48 10 3
6 32 10 64 10 32 0 78 7 101 15 25 9 83 16 139 17 l96 1 -98 7 c-53 3 -103 12
-109 18 -7 7 -40 12 -75 12 -35 0 -63 4 -63 10 0 6 24 10 54 10 30 0 67 5 83
11 15 6 87 17 158 24 l130 13 -95 1 c-52 1 -117 8 -144 16 -27 8 -71 15 -98
15 -74 0 -49 17 29 19 37 1 82 6 98 11 17 5 89 13 160 17 l130 8 -120 5 c-66
3 -133 11 -148 18 -16 7 -55 12 -88 12 -33 0 -59 4 -59 10 0 6 26 10 58 10 31
1 86 7 122 15 47 10 149 15 370 16 l305 2 -315 8 c-173 5 -325 13 -338 19 -13
5 -46 10 -75 10 -68 0 -101 19 -37 21 25 0 70 7 100 14 39 9 136 12 330 11
l275 -1 53 -29z m1126 -3 c-3 -21 -9 -40 -13 -44 -10 -9 -386 8 -438 20 -26 6
-73 15 -103 20 l-55 9 55 1 c30 1 78 8 105 15 35 10 113 14 253 15 l203 1 -7
-37z m1034 -45 c-22 -46 -46 -86 -52 -90 -6 -4 -8 -8 -3 -8 9 0 -32 -82 -46
-91 -5 -4 -53 -5 -106 -2 l-96 5 -34 -64 c-18 -34 -43 -79 -55 -98 -11 -19
-21 -39 -22 -45 -1 -6 -24 -51 -52 -101 -27 -49 -76 -139 -107 -199 -32 -61
-73 -138 -92 -173 l-35 -63 -151 4 c-128 4 -355 31 -307 36 58 7 179 23 255
35 l90 13 -61 2 c-34 0 -78 8 -97 16 -20 8 -62 16 -94 16 l-58 2 75 8 c41 4
88 12 103 18 16 6 47 11 70 12 23 0 51 4 62 9 16 7 13 9 -16 9 -20 1 -56 8
-79 16 -23 8 -63 15 -89 15 -25 0 -53 5 -61 10 -11 7 3 10 46 10 33 0 83 7
110 15 27 8 90 15 139 16 98 0 94 17 -5 18 -30 1 -77 8 -104 16 -27 8 -72 15
-100 15 -37 0 -47 3 -36 10 8 5 41 10 72 10 31 0 69 5 85 11 15 6 78 15 138
19 l110 7 -81 1 c-45 1 -101 9 -124 17 -23 8 -67 15 -96 15 -30 0 -54 5 -54
10 0 6 26 10 58 10 31 0 82 7 112 15 30 8 89 15 130 16 l75 2 -80 7 c-44 4
-93 13 -108 19 -16 6 -53 11 -83 11 -30 0 -54 5 -54 10 0 6 26 10 58 10 31 0
79 7 106 15 32 10 96 15 180 16 97 1 121 3 91 10 -22 4 -69 8 -105 9 -36 0
-90 7 -120 15 -30 8 -81 15 -113 15 -36 0 -56 4 -52 10 3 6 33 10 65 10 33 0
85 7 117 15 33 9 109 16 183 17 l125 1 -135 7 c-74 3 -145 12 -157 18 -12 7
-49 12 -82 12 -33 0 -63 5 -66 10 -4 6 16 10 52 10 32 0 83 7 113 15 30 8 82
16 115 16 33 1 -16 5 -110 10 -100 4 -182 12 -200 20 -16 7 -59 15 -95 19
l-65 6 50 7 c158 23 242 28 550 36 l340 8 -346 1 c-279 2 -355 5 -389 17 -23
8 -67 15 -96 15 -30 0 -54 5 -54 10 0 6 28 10 63 10 35 0 86 7 113 15 38 11
113 15 324 17 239 1 258 2 145 9 -71 3 -192 7 -267 8 -75 0 -149 6 -165 12
-15 6 -59 14 -98 19 l-70 7 55 2 c30 1 78 8 105 15 38 11 136 14 417 15 l368
1 -42 -82z m860 45 l-3 -38 -117 -3 c-124 -3 -269 9 -403 32 l-80 14 60 1 c33
1 80 8 105 15 32 11 102 15 243 15 l198 1 -3 -37z m-12 -105 c-4 -24 -20 -113
-36 -198 -28 -145 -104 -565 -116 -637 l-5 -33 -152 0 c-84 0 -170 5 -191 11
-22 6 -69 14 -105 19 l-66 7 64 2 c35 0 87 8 115 16 28 8 105 18 171 23 l120
9 -120 2 c-74 1 -135 7 -160 16 -22 8 -66 15 -98 15 -36 0 -56 4 -52 10 3 6
32 10 63 10 31 1 84 7 117 15 33 8 103 15 155 16 l95 2 -115 7 c-63 4 -128 13
-143 19 -16 6 -55 11 -88 11 -33 0 -59 4 -59 10 0 6 26 10 59 10 33 0 72 5 88
12 15 6 80 14 143 18 102 6 87 7 -132 11 l-248 4 -62 -74 c-35 -41 -85 -99
-113 -129 -27 -30 -51 -58 -53 -63 -5 -12 -298 -12 -340 0 -18 6 -60 11 -93
13 -67 3 -81 18 -16 18 23 0 69 7 102 15 33 8 94 15 135 15 41 0 82 5 90 10
11 7 -3 10 -47 10 -34 0 -74 5 -90 11 -15 7 -57 15 -93 18 l-65 7 50 7 c28 4
86 14 130 22 44 8 116 16 160 16 l80 2 -80 8 c-44 4 -93 12 -108 18 -16 6 -53
11 -83 11 -30 0 -54 5 -54 10 0 6 26 10 59 10 33 0 72 5 88 11 15 6 95 15 177
20 82 5 152 12 155 14 2 3 -48 5 -113 5 -65 0 -126 4 -136 10 -10 5 -49 14
-87 18 l-68 8 80 8 c44 4 88 12 97 17 10 5 99 11 199 14 100 3 185 7 187 10 3
3 -60 5 -140 5 -104 0 -158 4 -188 15 -23 8 -68 15 -99 16 l-56 1 85 15 c47 8
142 17 212 21 70 4 129 10 133 13 3 3 -34 9 -82 12 -49 3 -101 10 -116 16 -16
6 -55 11 -88 11 -33 0 -59 4 -59 10 0 6 28 10 63 10 34 0 84 7 111 15 29 9 95
16 160 17 l111 1 -95 7 c-52 4 -108 12 -123 18 -16 7 -56 12 -90 12 -44 0 -58
3 -47 10 8 5 38 10 65 10 28 0 77 7 110 15 33 8 96 15 140 16 l80 2 -77 8
c-43 4 -88 14 -100 20 -13 7 -52 16 -88 19 l-65 7 55 2 c30 1 88 10 129 21 41
11 84 20 95 21 37 2 -46 21 -111 26 -35 3 -63 9 -63 14 0 5 28 9 63 9 34 1 89
7 122 15 33 8 110 15 170 16 l110 2 -105 7 c-58 5 -121 13 -140 19 -19 6 -59
11 -87 11 -29 0 -53 5 -53 10 0 6 28 10 63 10 34 0 78 5 97 12 19 6 132 14
250 18 118 3 226 7 239 8 22 2 23 0 17 -40z"/>
<path d="M1608 1573 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1486 1328 c-99 -193 -156 -300 -169 -315 -7 -10 27 -13 164 -12
l174 1 -55 15 c-30 8 -85 17 -122 20 -83 6 -90 23 -10 23 31 0 82 7 112 15 30
8 93 15 140 16 l85 1 -70 14 c-38 8 -99 17 -135 20 -85 8 -102 24 -24 24 32 0
79 6 104 14 25 7 125 17 224 21 162 6 178 5 173 -9 -5 -13 1 -16 31 -16 20 0
53 4 72 10 19 5 84 11 143 14 60 2 110 6 113 8 2 3 -33 8 -78 11 -46 3 -96 10
-111 16 -46 18 -144 14 -155 -5 -8 -15 -27 -16 -163 -12 -85 3 -167 11 -184
18 -16 6 -57 15 -90 20 -58 7 -59 8 -15 9 25 0 72 8 103 16 32 8 81 15 110 16
29 0 75 4 102 9 45 7 42 7 -40 9 -49 1 -112 8 -139 16 -27 8 -76 15 -108 15
-37 0 -57 4 -53 10 3 6 34 10 68 10 34 0 84 7 111 15 31 9 97 16 175 17 l126
1 -150 7 c-83 3 -160 12 -172 18 -12 7 -46 12 -75 12 -29 0 -60 5 -68 10 -11
7 1 10 40 10 30 0 71 5 90 11 19 5 64 14 100 18 52 7 28 9 -115 10 l-181 1
-78 -152z"/>
<path d="M2799 1467 c4 -4 43 -7 87 -5 l79 3 -87 5 c-48 3 -83 1 -79 -3z"/>
<path d="M2733 1377 l-28 -30 30 8 c17 4 71 11 120 15 74 6 80 7 35 9 -30 1
-72 8 -92 15 -36 13 -38 12 -65 -17z"/>
<path d="M2657 1292 c-15 -15 -27 -31 -27 -35 0 -11 51 -8 90 4 19 6 82 14
140 19 l105 7 -88 1 c-52 1 -102 8 -124 17 -50 21 -65 19 -96 -13z"/>
<path d="M2592 1218 c-7 -7 -12 -16 -12 -20 0 -4 60 -7 133 -7 105 1 121 3 77
9 -30 4 -68 13 -83 19 -37 15 -100 14 -115 -1z"/>
<path d="M2042 1110 c-12 -16 -22 -32 -22 -35 0 -9 58 -5 87 6 15 6 105 14
198 16 94 3 172 7 174 9 2 2 -56 4 -131 4 -91 0 -151 5 -184 15 -73 22 -97 19
-122 -15z"/>
<path d="M1973 1051 l28 -6 -31 -35 c-30 -33 -30 -35 -10 -38 11 -2 42 4 67
12 27 9 80 16 127 16 45 0 88 5 96 10 10 7 -6 10 -55 10 -38 0 -101 9 -140 20
-38 11 -79 19 -90 19 -13 -1 -10 -4 8 -8z"/>
<path d="M1894 936 c-7 -7 -25 -22 -40 -34 l-29 -21 37 -1 c20 0 63 7 95 15
31 8 91 16 133 16 l75 2 -68 8 c-38 4 -82 12 -99 18 -41 15 -90 13 -104 -3z"/>
<path d="M4668 1473 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4738 1463 c23 -2 59 -2 80 0 20 2 1 4 -43 4 -44 0 -61 -2 -37 -4z"/>
<path d="M4636 1383 c-14 -15 -24 -28 -22 -30 1 -2 27 1 56 7 29 5 80 11 114
11 l61 2 -78 7 c-42 4 -83 13 -91 19 -11 10 -19 6 -40 -16z"/>
<path d="M4546 1285 l-27 -35 33 0 c18 0 50 5 70 10 20 6 85 14 145 19 l108 8
-90 2 c-49 1 -108 8 -130 16 -61 22 -80 19 -109 -20z"/>
<path d="M4480 1210 c-10 -20 -8 -20 132 -19 114 1 131 3 83 9 -33 4 -73 13
-88 19 -16 6 -48 11 -72 11 -34 0 -47 -5 -55 -20z"/>
<path d="M9841 1804 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9436 1714 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z"/>
<path d="M9413 1668 c-25 -40 -50 -99 -41 -96 12 4 16 11 39 63 7 17 15 33 17
38 6 12 -6 8 -15 -5z"/>
<path d="M9761 1664 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9790 1661 c-12 -24 -13 -34 -1 -27 9 6 22 46 15 46 -2 0 -8 -9 -14
-19z"/>
<path d="M9314 1496 c-9 -15 -14 -29 -12 -32 3 -2 13 7 22 22 10 14 15 28 13
31 -3 2 -13 -7 -23 -21z"/>
<path d="M9720 1479 c-7 -16 -18 -28 -24 -26 -6 3 -12 -7 -13 -21 l-1 -26 18
24 c31 42 51 80 42 80 -5 0 -15 -14 -22 -31z"/>
<path d="M9263 1418 c-23 -30 -24 -41 -2 -21 12 12 20 25 17 28 -3 3 -10 0
-15 -7z"/>
<path d="M9260 1369 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M9620 1340 c-14 -22 -28 -40 -31 -40 -4 0 -19 -16 -33 -35 -15 -19
-23 -35 -17 -35 5 0 31 28 58 63 59 74 67 87 56 87 -5 0 -19 -18 -33 -40z"/>
<path d="M9180 1308 c-23 -29 -60 -72 -82 -96 -22 -24 -38 -46 -35 -49 6 -6
56 47 57 60 0 4 14 6 31 5 21 -2 34 2 37 12 5 13 3 13 -12 1 -9 -8 -19 -11
-22 -8 -8 8 33 73 55 90 22 16 35 37 22 37 -5 0 -28 -24 -51 -52z"/>
<path d="M9516 1204 c-9 -24 -2 -26 12 -4 7 11 8 20 3 20 -5 0 -12 -7 -15 -16z"/>
<path d="M9465 1190 c-10 -11 -15 -20 -11 -20 11 0 39 30 34 36 -3 2 -13 -5
-23 -16z"/>
<path d="M9470 1155 c-12 -13 -27 -22 -31 -19 -5 3 -6 -2 -2 -12 5 -13 11 -11
36 16 16 17 27 33 25 36 -3 2 -15 -7 -28 -21z"/>
<path d="M9090 1161 c0 -5 7 -11 14 -14 10 -4 13 -1 9 9 -6 15 -23 19 -23 5z"/>
<path d="M8990 1092 c-31 -31 -70 -69 -88 -84 -18 -15 -32 -30 -32 -34 0 -3 7
-1 15 6 8 6 19 9 24 5 7 -3 8 0 5 9 -4 10 3 20 20 27 14 7 26 18 26 26 0 7 4
13 9 13 12 0 57 43 70 68 7 12 10 22 9 21 -2 0 -28 -26 -58 -57z"/>
<path d="M9388 1133 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M9361 1104 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9400 1095 c-20 -24 -6 -28 15 -5 10 11 13 20 8 20 -6 0 -16 -7 -23
-15z"/>
<path d="M9022 1074 c-7 -8 -8 -14 -3 -14 10 0 25 19 20 25 -2 1 -10 -3 -17
-11z"/>
<path d="M9365 1060 c4 -6 -13 -27 -37 -48 -74 -64 -121 -112 -117 -117 3 -2
45 33 93 79 69 65 85 84 72 89 -10 4 -15 3 -11 -3z"/>
<path d="M9307 1042 c13 -13 16 -13 29 0 13 13 11 15 -15 15 -26 0 -27 -2 -14
-15z"/>
<path d="M9229 963 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M8800 918 c-112 -90 -206 -158 -227 -164 -13 -3 -23 -14 -23 -23 0
-9 3 -12 8 -6 7 10 41 31 92 57 13 7 36 25 51 41 14 15 30 27 34 27 4 0 25 15
45 33 21 17 49 39 62 48 12 10 20 20 17 23 -4 4 -30 -13 -59 -36z"/>
<path d="M8870 927 c0 -8 -8 -18 -17 -20 -17 -4 -17 -5 0 -6 18 -1 35 25 23
36 -3 4 -6 -1 -6 -10z"/>
<path d="M9180 882 c0 -5 -12 -14 -26 -21 -15 -6 -24 -14 -21 -17 3 -3 19 2
36 12 31 18 40 34 21 34 -5 0 -10 -4 -10 -8z"/>
<path d="M9110 822 c-30 -14 -70 -44 -70 -54 0 -5 20 5 45 23 51 37 59 46 25
31z"/>
<path d="M9050 810 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M8635 760 c-4 -6 3 -10 14 -10 25 0 27 6 6 13 -8 4 -17 2 -20 -3z"/>
<path d="M8946 732 c-4 -7 -2 -12 4 -12 6 0 8 -5 4 -11 -4 -7 7 -4 24 7 34 19
37 33 4 15 -15 -8 -21 -8 -24 1 -3 9 -6 9 -12 0z"/>
<path d="M8470 689 c-24 -16 -38 -29 -31 -29 20 0 101 42 101 52 0 15 -24 7
-70 -23z"/>
<path d="M8890 700 c-8 -5 -12 -11 -10 -14 3 -2 14 2 24 10 22 15 10 19 -14 4z"/>
<path d="M8891 670 c-13 -11 -19 -20 -13 -20 5 0 20 9 33 20 13 10 20 19 14
19 -5 0 -21 -9 -34 -19z"/>
<path d="M8387 641 c-17 -18 -105 -63 -215 -112 -30 -13 -50 -25 -45 -26 5 -2
35 8 67 22 31 14 63 25 71 25 8 0 15 5 15 10 0 6 24 21 53 34 28 13 61 33 71
45 23 25 8 27 -17 2z"/>
<path d="M8758 653 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M8830 635 c-19 -10 -27 -10 -33 -2 -6 8 -7 6 -3 -5 3 -10 1 -18 -5
-18 -6 0 -22 -9 -36 -20 -14 -11 -30 -17 -36 -13 -6 3 -7 1 -3 -6 5 -8 24 -3
64 20 51 28 94 60 82 59 -3 -1 -16 -7 -30 -15z"/>
<path d="M398 613 c-2 -5 -2 -47 0 -95 l3 -88 2562 0 c1418 0 2579 -4 2600 -9
21 -5 37 -5 37 0 0 5 438 9 1048 9 l1047 0 75 24 c107 33 294 105 359 137 l56
28 -3892 0 c-2140 1 -3893 -2 -3895 -6z"/>
<path d="M8660 540 c0 -6 -10 -8 -23 -5 -16 4 -29 0 -42 -15 -17 -19 -93 -60
-111 -60 -3 0 -3 7 0 16 3 9 2 12 -5 9 -6 -4 -8 -13 -5 -21 3 -8 -1 -14 -8
-14 -7 0 -19 -7 -26 -15 -13 -16 -1 -21 16 -7 5 5 39 22 77 37 37 15 67 34 67
42 0 7 6 13 13 14 42 3 59 7 69 17 9 9 8 12 -5 12 -9 0 -17 -5 -17 -10z"/>
<path d="M8228 513 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8188 503 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8076 492 c-2 -4 -41 -21 -85 -37 -45 -17 -81 -33 -81 -36 0 -5 94
25 145 46 25 11 50 35 36 35 -5 0 -12 -3 -15 -8z"/>
<path d="M8438 493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8428 463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8400 430 c0 -11 4 -20 9 -20 5 0 7 9 4 20 -3 11 -7 20 -9 20 -2 0
-4 -9 -4 -20z"/>
<path d="M7858 405 c-2 -3 -54 -18 -114 -34 -60 -16 -102 -30 -93 -30 8 -1 50
8 94 20 43 11 84 18 92 15 8 -3 11 -1 8 4 -3 6 3 10 14 10 11 0 23 5 26 10 6
10 -17 14 -27 5z"/>
<path d="M8320 389 c-19 -10 -41 -19 -49 -19 -8 0 -11 -3 -8 -6 7 -7 96 23
112 37 17 16 -19 8 -55 -12z"/>
<path d="M5735 364 c39 -14 82 -27 96 -29 14 -2 46 -10 70 -18 66 -22 119 -31
119 -19 0 9 -84 28 -104 23 -5 -1 -12 2 -15 7 -3 5 -24 12 -46 15 -22 3 -69
15 -105 26 -90 29 -100 25 -15 -5z"/>
<path d="M7880 370 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7848 343 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7580 331 c-8 -5 -55 -17 -105 -27 -76 -15 -85 -19 -60 -25 43 -10
78 -9 45 0 -20 6 -10 10 50 21 77 14 146 40 105 40 -11 0 -27 -5 -35 -9z"/>
<path d="M7990 330 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M8188 333 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8048 323 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8092 321 c15 -5 -68 -31 -100 -31 -13 0 -30 -7 -39 -15 -8 -8 -19
-13 -24 -10 -5 4 -9 1 -9 -5 0 -12 38 -4 55 11 5 5 20 9 35 10 14 0 57 10 95
23 l70 23 -47 -1 c-27 0 -43 -3 -36 -5z"/>
<path d="M6060 290 c14 -4 18 -9 10 -9 -12 -1 -12 -3 2 -11 10 -7 35 -7 70 0
l53 9 -60 -4 c-34 -2 -52 -1 -41 4 19 7 18 8 -5 14 -34 9 -59 7 -29 -3z"/>
<path d="M7313 273 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M6223 263 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M7248 263 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6165 249 c22 -4 49 -8 60 -7 16 0 15 2 -5 8 -14 4 -41 8 -60 8 l-35
0 40 -9z"/>
<path d="M6348 253 c12 -2 22 -8 22 -12 0 -4 41 -8 92 -9 50 -2 94 -5 99 -7 4
-3 17 -1 30 3 16 6 -9 11 -86 16 -61 3 -126 8 -145 10 -19 2 -25 1 -12 -1z"/>
<path d="M7100 251 c0 -8 79 -3 89 5 2 2 -17 4 -42 4 -26 0 -47 -4 -47 -9z"/>
<path d="M7748 253 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7818 248 c-32 -6 -58 -14 -58 -19 0 -9 43 -3 105 17 48 14 31 15
-47 2z"/>
<path d="M7663 243 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M6760 230 l-125 -6 145 -1 c80 -1 183 2 230 6 l85 8 -105 -1 c-58 0
-161 -3 -230 -6z"/>
<path d="M6303 223 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M7666 221 c-19 -7 -19 -8 2 -13 12 -4 20 -2 17 3 -4 5 9 6 27 3 25
-4 29 -3 18 5 -17 12 -37 13 -64 2z"/>
<path d="M6396 210 c29 -4 57 -11 63 -14 6 -4 17 -1 24 6 11 11 0 13 -62 14
-71 2 -73 1 -25 -6z"/>
<path d="M7543 203 c-7 -2 -13 -9 -13 -14 0 -14 26 -10 40 6 10 12 10 15 -1
14 -8 0 -20 -3 -26 -6z"/>
<path d="M7600 200 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M6515 190 c-4 -6 5 -10 24 -8 16 1 27 6 25 10 -7 11 -42 10 -49 -2z"/>
<path d="M6715 170 c39 -9 172 -14 164 -6 -2 2 -49 6 -104 9 -68 4 -87 3 -60
-3z"/>
<path d="M7280 170 l-85 -6 80 -1 c44 -1 91 3 105 7 29 9 26 9 -100 0z"/>
<path d="M6948 162 c-9 -4 42 -8 113 -9 81 -1 127 1 124 7 -7 11 -211 12 -237
2z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
